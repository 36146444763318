<template>
	<div class="grid crud-demo">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button label="Neu" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
							<Button label="Löschen" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedStandort || !selectedStandort.length" />
						</div>
					</template>

					<template v-slot:end>
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template>
				</Toolbar>

				<DataTable  ref="dt" :value="standort" v-model:selection="selectedStandort" dataKey="ID" :paginator="true" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="{first} bis {last} von {totalRecords} Standorte" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Standorte</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Suchen..." />
                            </span>
						</div>
					</template>

					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="ID" header="ID" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">ID</span>
							{{slotProps.data.ID}}
						</template>
					</Column>
					<Column field="UNTERNEHMEN" header="Unternehmen" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Unternehmen</span>
							{{slotProps.data.UNTERNEHMEN}}
						</template>
					</Column>
					<Column field="BEZEICHNUNG" header="Bezeichnung" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Bezeichnung</span>
							{{slotProps.data.BEZEICHNUNG}}
						</template>
					</Column>
					<Column field="STRASSE" header="Strasse" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Strasse</span>
							{{slotProps.data.STRASSE}}
						</template>
					</Column>
          <Column field="NR" header="Nr" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nummer</span>
              {{slotProps.data.NR}}
            </template>
          </Column>
          <Column field="ORT" header="Ort" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Ort</span>
              {{slotProps.data.ORT}}
            </template>
          </Column>
          <Column field="PLZ" header="Plz" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Ort</span>
              {{slotProps.data.PLZ}}
            </template>
          </Column>
          <Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editStandort(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteStandort(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog  v-model:visible="standortDialog" :style="{width: '450px'}" header="Standort Details" :modal="true" class="p-fluid">
					<div class="field">
						<label for="BEZEICHNUNG">Bezeichnung</label>
						<InputText id="bezeichnung" v-model.trim="standortObj.BEZEICHNUNG" required="true" autofocus :class="{'p-invalid': submitted && !standortObj.BEZEICHNUNG}" />
						<small class="p-invalid" v-if="submitted && !standortObj.Bezeichnung">Bezeichnung muss angegeben werden.</small>
					</div>
          <div class="field">
            <label for="STRASSE">Strasse</label>
            <InputText id="strasse" v-model.trim="standortObj.STRASSE" required="true" autofocus :class="{'p-invalid': submitted && !standortObj.STRASSE}" />
            <small class="p-invalid" v-if="submitted && !standortObj.STRASSE">Strasse muss angegeben werden.</small>
          </div>
          <div class="field">
            <label for="NR">Nummer</label>
            <InputText id="nr" v-model.trim="standortObj.NR" required="true" autofocus :class="{'p-invalid': submitted && !standortObj.NR}" />
            <small class="p-invalid" v-if="submitted && !standortObj.NR">Nr muss angegeben werden.</small>
          </div>
          <div class="field">
            <label for="ORT">Ort</label>
            <InputText id="ort" v-model.trim="standortObj.ORT" required="true" autofocus :class="{'p-invalid': submitted && !standortObj.ORT}" />
            <small class="p-invalid" v-if="submitted && !standortObj.ORT">Ort muss angegeben werden.</small>
          </div>
          <div class="field">
            <label for="PLZ">PLZ</label>
            <InputText id="plz" v-model.trim="standortObj.PLZ" required="true" autofocus :class="{'p-invalid': submitted && !standortObj.PLZ}" />
            <small class="p-invalid" v-if="submitted && !standortObj.PLZ">Postleitzahl muss angegeben werden.</small>
          </div>
          <div class="field">
            <label for="FK_ID_UNTERNEHMEN" class="mb-3">Unternehmens ID</label>
            <Dropdown id="fk_id_unternehmen" v-model="standortObj.FK_ID_UNTERNEHMEN" :options="unternehmenCustom"  placeholder="Unternehmen">
              <template #value="slotProps">
                <div v-if="slotProps.value">
                  <span :class="'product-badge status-' +slotProps.value">{{slotProps.value}}</span>
                </div>
                <span v-else>
									{{slotProps.placeholder}}
								</span>
              </template>
            </Dropdown>
          </div>
					<template #footer>
						<Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveStandort" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteStandortDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="standortObj"><b>{{standortObj.BEZEICHNUNG}}</b> wirklich löschen?</span>
					</div>
					<template #footer>
						<Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteStandortDialog = false"/>
						<Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteStandort" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteStandorteDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="standortObj">Ausgewählte Standorte wirklich löschen?</span>
					</div>
					<template #footer>
						<Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteStandorteDialog = false"/>
						<Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteSelectedStandorte" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import DataService from "@/service/DataService";

export default {
	data() {
		return {
      unternehmen: null,
      unternehmenCustom: [],
      standort: null,
      standortDialog: false,
      deleteStandortDialog: false,
      deleteStandorteDialog: false,
      neu: false,
      standortObj: {},
      selectedStandort: null,
			filters: {},
			submitted: false,
		}
	},
  dataService: null,
	created() {
    this.dataService = new DataService();
		this.initFilters();
	},
	async mounted() {
    this.unternehmen = await this.dataService.getUnternehmen();

    this.unternehmen.forEach((el) => {
      let string = `${el.ID} ${el.NAME}`;
      this.unternehmenCustom.push(string);
    })

    this.standort = await this.dataService.getStandorteAll();
	},
  watch: {
    mitarbeiter: function (value){
      console.log(value)
    }
  },
	methods: {
		openNew() {
      this.standortObj = {};
      this.submitted = false;
      this.standortDialog = true;
      this.neu = true;
		},
		hideDialog() {
			this.standortDialog = false;
			this.submitted = false;
      this.neu = false;
		},
    async saveStandort(){
      this.submitted = true;

      if(this.neu){
          await this.dataService.saveStandort(this.standortObj);
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Standort erstellt', life: 3000});
          setTimeout(async()=> {this.standort = await this.dataService.getStandorteAll()}, 100);
          this.unternehmen = await this.dataService.getUnternehmen();
          this.standortDialog = false;
          this.standortObj = {};
      } else {
          await this.dataService.updateStandort(this.standortObj);
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Standort aktualisiert', life: 3000});
          setTimeout(async()=> {this.standort = await this.dataService.getStandorteAll()}, 100);

        this.unternehmen = await this.dataService.getUnternehmen();
          this.standortDialog = false;
          this.standortObj = {};
      }
      this.neu = false;

    },
    editStandort(standort) {
      this.standortObj = {...standort};
      this.standortDialog = true;
      this.neu = false;
    },
    confirmDeleteStandort(standort) {
      this.standortObj = standort;
      this.deleteStandortDialog = true;
    },
    async deleteStandort() {
      let data = [];
      data.push(this.standortObj);
      await this.dataService.deleteStandort(data);

      this.deleteStandortDialog = false;
      this.standortObj = {};
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Standort gelöscht', life: 3000});
      setTimeout(async()=> {this.standort = await this.dataService.getStandorteAll()}, 100);

    },
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteStandorteDialog = true;
		},
    async deleteSelectedStandorte() {
      this.deleteStandorteDialog = false;
      await this.dataService.deleteStandort(this.selectedStandort);
      this.selectedStandort = null;
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Standorte gelöscht', life: 3000});
      setTimeout(async()=> {this.standort = await this.dataService.getStandorteAll()}, 100);

    },
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/styles/badges.scss';
</style>
